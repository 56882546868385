import { translationInstance } from '@/localization/i18n'

import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'

import { updatePreviousSearches } from '../services/previousSearchesService'
import { decodeQueryString } from '../services/urlQueryParamservice'
import LandingView from '../views/LandingView.vue'
import VerifyAppView from '../views/VerifyAppView.vue'
import Widget from '../views/Widget.vue'

const loadedLanguages = ['dk', 'en', 'fi', 'no', 'se'] as string[]

export function setI18nLanguage (lang: string = '') {
  if (lang === 'sv') {
    lang = 'se'
  } else if (lang === 'da') {
    lang = 'dk'
  }
  if (!loadedLanguages.includes(lang)) {
    lang = 'en'
  }

  document.querySelector('html')?.setAttribute('lang', lang)
  return lang
}

let lang = translationInstance.i18next.language.slice(0, 2)
lang = setI18nLanguage(lang)

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: `/${lang}`
  },
  {
    path: '/:lang',
    name: 'landingView',
    component: LandingView,
    props: { language: lang },
    beforeEnter: ({ query }, from, next) => {
      const queryID = query.id || query.ID
      const queryToken = query.p
      const nocache = query.nocache as string|undefined || ''
      const shipmentId = decodeQueryString(queryID).shipmentId
      if (shipmentId !== null || '') updatePreviousSearches(shipmentId as string)
      if (queryID && queryToken) {
        next({
          name: 'WidgetWithToken',
          params: {
            lang,
            shipId: shipmentId,
            token: query.p as string,
            nocache
          }
        })
      } else if (queryID) {
        next({
          name: 'Widget',
          params: {
            lang,
            shipId: shipmentId,
            nocache
          }
        })
      } else if (query.key) {
        next({
          name: 'VerificationMSg',
          params: {
            lang,
            mNumber: query.key as string,
            token: query.token as string,
            langVerification: query.lang as string,
            country: query.country as string,
            action: query.action as string
          }
        })
      } else {
        next()
      }
    }
  },
  {
    path: '/:lang/?id=:shipId',
    name: 'Widget',
    component: Widget,
    props: true,
    beforeEnter: ({ query }, from, next) => {
      const queryID = query.id || query.ID
      if (queryID !== 'undefined') next()
    }
  },
  {
    path: '/:lang/?id=:shipId&p=:token',
    name: 'WidgetWithToken',
    component: Widget,
    props: true,
    beforeEnter: ({ query }, from, next) => {
      const queryID = query.id || query.ID
      if (queryID !== 'undefined') next()
    }
  },
  {
    path: '/:lang/?key=:mNumber&token=:token&lang=:langVerification&country=:country&action=:action',
    name: 'VerificationMSg',
    component: VerifyAppView,
    props: true
  },
  {
    path: '/:lang/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: () => {
      return {
        name: 'landingView'
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
