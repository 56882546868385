import i18next, { type i18n } from 'i18next';

import I18NextVue, { useTranslation } from 'i18next-vue';

import LanguageDetector from 'i18next-browser-languagedetector';

import * as languageResources from './translations'

type project = 'tracking-spa' | 'widget'

const getLanguageResources = (project: project) => {
    const resources: Record<string, any> = {}
    for(const lang in languageResources) {
        resources[lang] = {
            translation: (languageResources as any)[lang][project]
        }
    }
    
    return resources
}

// Temp function to change language while we don't have any other way to select language, for testing purpose.
const changeLanguage = (locale?: string) => {
    const langanguage = locale || (i18next.language === 'en-GB' ? 'sv-SE' : 'en-GB')
    console.log('@shared - changing language to', { locale, langanguage })
    i18next.changeLanguage(langanguage)
    return new Date().getTime()
}

const convertLocale = (locale?: string) => {
    return {
        'en': 'en-GB',
        'da': 'da-DK',
        'dk': 'da-DK',
        'fi': 'fi-FI',
        'sv': 'sv-SE',
        'se': 'sv-SE',
        'no': 'no-NO'
    }[locale?.toLowerCase() || 'en']
}

class TranslationService {
    project?: project;
    i18next: i18n = i18next;

    constructor(project: project) {
        this.project = project;
        this.init()
    }

    init() {
        if(!this.project) return;

        this.i18next.use(LanguageDetector)
        this.i18next.init({
            detection: {
                order: ['navigator']
            },
            resources: getLanguageResources(this.project),
            fallbackLng: 'en'
        })
    }

    usePlugin(app: any) {
        app.use(I18NextVue, { i18next: this.i18next });
    }
}


function createTranslationInstance(project: project) {
    const instance = new TranslationService(project)
    instance.init()
    return instance
}

export { createTranslationInstance, useTranslation, convertLocale, changeLanguage }