export const goToDownloadPage = (language: string) => {
  window.location.href = getTargetDownloadPage(language)
  // console.log('language in click: ', language)
}

export const getOldDownloadPage = () => {
  if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
    return 'https://play.google.com/store/apps/details?id=se.postnord.private'
  } else if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
    return 'https://apps.apple.com/se/app/postnord-sp%C3%A5ra-dina-paket/id396871673?mt=8&ign-mpt=uo%3D4'
  } else {
    return 'https://app.postnord.com/download/'
  }
}

export const getTargetDownloadPage = (language: string) => {
  const languageToPathNameMap: Record<string, string> = {
    sv: 'https://wjf66.app.goo.gl/PNApp',
    en: 'https://wjf66.app.goo.gl/pnapp_global',
    da: 'https://wjf66.app.goo.gl/pnapp_dk',
    fi: 'https://wjf66.app.goo.gl/pnapp_fi'
  }

  return languageToPathNameMap[language] || getOldDownloadPage()
}
