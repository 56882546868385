<template>
  <div class="container">
    <transition v-if="getWidgetLanguage() === 'da'" name="fade">
      <div class="app-nudge-component">
        <app-nudge />
      </div>
    </transition>
    <transition name="fade">
      <div class="btn-container">
        <div class="buttonGroup">
          <pn-button @click="goToLandingView" appearance="light" :icon="arrow_left" left-icon small
            variant="borderless">{{ t('SEARCH_ANOTHER_SHIPMENT') }}
          </pn-button>
        </div>
      </div>
    </transition>
    <pn-widget :locale="getWidgetLanguage()" :shipment-id="shipId" :flex-change-token="token ? shipId + token : undefined"></pn-widget>

    <transition v-if="getWidgetLanguage() !== 'da'" name="fade">
      <app-promo @tileClick="handleTileClick" class="app-promo-component"></app-promo>
    </transition>
  </div>
</template>

<script setup lang="ts">
import AppPromo from '../components/AppPromo.vue';
import AppNudge from '../components/AppNudge.vue';
import { arrow_left } from 'pn-design-assets/pn-assets/icons'

import { onMounted } from "vue"
import { useRouter } from "vue-router"
import { useTranslation } from "@public-tracking/shared/services/localization"
import { goToDownloadPage } from "../utils/download-app"

const { t } = useTranslation()
const router = useRouter()

const props = defineProps<{
  lang?: string
  shipId?: string
  token?: string
}>()

const getWidgetLanguage = () => {
  if(props.lang === 'se') return 'sv';
  if(props.lang === 'dk') return 'da';
  return props.lang
}

const handleTileClick = (lang: string) => {
  goToDownloadPage(lang)
}

onMounted(() => {
  console.log('v2', props.lang);

  // @ts-ignore
  if (import.meta.env.NODE_ENV !== 'production') {
    if (props.token) {
      console.log('p from urlq:', props.token);
    } else {
      console.log('[attracking.postnord.com] no token');
    }
  }
})

const goToLandingView = () => {
  window.dataLayer.push({ event: 'to_landing_page_clicked' });
  router.push({ name: 'landingView' }).catch(() => { });
}
</script>

<style lang="scss" scoped>
.buttonGroup {
  display: flex;
  justify-content: space-between;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1.25em;
  margin-right: 1.25em;

  .btn-container {
    width: 100%;
    margin-top: 2em;
    margin-left: 0em;
    max-width: 680px;

    @media only screen and (max-width: 880px) {
      margin-left: -2em;
    }
  }

  #widget-wrapper {
    width: 100%;
  }

  .app-promo-component {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter-active {
  transition: 0.75s ease;
  transition-delay: 1s;
}
</style>
