<template>
  <div class="app">
    <header>
      <img src="./assets/images/pn-logo.svg" alt="" width="175" height="34"/>
    </header>
    <router-view />
    <footer-component />
  </div>
</template>

<script setup lang="ts">
import { t } from 'i18next'
import FooterComponent from './components/FooterComponent.vue';
import { onMounted } from "vue"

onMounted(() => {
  document.title = t('SITE_TITLE').toString();
})
</script>

<style lang="scss">
.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
header {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}
</style>
