<template>
  <div class="search-container">
    <h1 class="pn-2xl">{{ t('TRACKING_HEADING') }}</h1>
    <pn-search-field
      button="icon"
      :placeholder="t('TRACKING_SEARCH_PLACEHOLDER')"
      :value="searchInput"
      @input="searchQuery"
      @search="searchClicked"
    />
    <previous-search
      class="previous-search-component"
      @clickedPrevSearch="prevSearchClicked"
      :previousSearches="localPreviousSearches"
  />
  </div>
</template>

<script setup lang="ts">
import PreviousSearch from './PreviousSearch.vue';
import {
  getPreviousSearches,
  updatePreviousSearches
} from '../services/previousSearchesService';
import { onMounted, ref, computed } from "vue"

import { t } from 'i18next'

const searchInput = ref('');
const localPreviousSearches = ref([]);

const emit = defineEmits(['clickedSearch'])

onMounted(() => {
  localPreviousSearches.value = getPreviousSearches();
})

const trimmedSearchInput = computed(() => {
  return searchInput.value.replace(/\s/g, '');
})

const searchQuery = (event: any) => {
  searchInput.value = event.target.value;
}

const searchClicked = () => {
  if (trimmedSearchInput.value !== '' || null) { updatePreviousSearches(trimmedSearchInput.value); }
  window.dataLayer.push({ event: 'search_input_clicked' });
  emit('clickedSearch', trimmedSearchInput.value);
}

const prevSearchClicked = (event: any) => {
  searchInput.value = event;
  updatePreviousSearches(trimmedSearchInput.value);
  emit('clickedSearch', trimmedSearchInput.value);
}

</script>

<style lang="scss" scoped>
.search-container {
  h1 {
    text-align: left;
    margin-bottom: 0.5em;
  }
  pn-search-field {
    width: 100%;
  }
  .previous-search-component {
    margin-top: 1em;
  }
}
@media only screen and (max-width: 880px) {
  .search-container {
    margin-top: 2em;
    margin-left: 1em;
    margin-right: 1em;
  }
}
</style>
