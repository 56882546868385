<template>
  <div class="verification-container">
    <div class="tile-container">
      <pn-tile
        v-if="action === 'validate'"
        :illustration="mobile_parcel_italy"
        @click="clickedVerificationToken"
      >
        <p>
          <strong>{{ t('VERIFICATION_MSG_HEADER_BOLD')}}</strong> {{ t('VERIFICATION_MSG_USE_CODE') }}:
        </p>
        <div class="verification-nr-box-container">
          <div class="verification-nr">
            <input type="hidden" ref="verificationCodeRef" :value="token"/>
            {{ token }}
          </div>
        </div>
        <p>
          {{ t('VERIFICATION_TO_VALIDATE') }} {{ mNumber }} {{ t('VERIFICATION_ACTION') }}
        </p>
        <pn-button appearance="light" variant="outlined" :icon="layers" small>{{ t('COPY_BUTTON') }}</pn-button>
      </pn-tile>
      <pn-tile
        v-if="action === 'deleteall'"
        illustration="mobile-notification"
        @click="clickedVerificationToken"
      >
        <p>
          <strong>{{ t('VERIFICATION_MSG_HEADER_BOLD')}}</strong> {{ t('VERIFICATION_MSG_USE_CODE') }}:
        </p>
        <div class="verification-nr-box-container">
          <div class="verification-nr">
            <input type="hidden" ref="verificationCodeRef" :value="token"/>
            {{ token }}
          </div>
        </div>
        <p>
          {{ t('VERIFICATION_TO_DELETE') }}
          {{ mNumber }} {{ t('VERIFICATION_TO_DELETE_DEVICES') }}.
        </p>
        <pn-button appearance="secondary" :icon="layers" small>{{ t('COPY_BUTTON') }}</pn-button>
      </pn-tile>
    </div>
    <pn-toast v-if="showToast" appearance="success" :closable="true" icon="save">{{token}} {{ t('SAVED_TO_CLIPBOARD') }}</pn-toast>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue"
import { changeLanguage } from "@public-tracking/shared/services/localization"
import { t } from 'i18next'
import { layers } from 'pn-design-assets/pn-assets/icons'
import { mobile_parcel_italy } from 'pn-design-assets/pn-assets/illustrations'


console.log('tile illustration', mobile_parcel_italy)

/**
 * example: /?key=46734441448&token=4513&lang=en&country=se&action=validate
 **/
const props = defineProps<{
  mNumber?: string // key
  token?: any // token number that validates
  langVerification?: string // language
  country?: string // country
  action?: string // can be validate or deleteall
}>()

const showToast = ref(false);
const verificationCodeRef = ref<HTMLInputElement>()

onMounted(() => {
  changeLanguage(props.langVerification);
})

// show toast changed state
watch(showToast, () => {
  if (showToast.value) {
    setTimeout(() => {
      showToast.value = false;
    }, 2000);
  }
})

const clickedVerificationToken = () => {
  const copyText = verificationCodeRef.value as HTMLInputElement;
  copyText.setAttribute('type', 'text');
  copyText.select();
  document.execCommand('copy');
  showToast.value = true;
  copyText.setAttribute('type', 'hidden');
  window.getSelection()?.removeAllRanges();
}
</script>

<style lang="scss" scoped>
.verification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1.25em;
  margin-left: 1.25em;
  margin-bottom: 2em;
  margin-top: 1em;

  .tile-container {
    width: 20em;
    height: 34em;
    margin-top: 1em;
    margin-bottom: 1em;

    pn-tile {
      .verification-nr-box-container {
        display: flex;
        justify-content: center;
        .verification-nr {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $blue700;
          color: white;
          font-size: 3rem;
          font-weight: 500;
          height: 2em;
          width: 4em;
          border-radius: 0.5em;
          user-select: text;
          margin-top: 0.5em;
        }
      }
      pn-button {
        width: 90%;
        margin-top: 1em;
      }
    }
  }
}
</style>
