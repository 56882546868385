/**
 * the url query param id MIGHT be encoded with api key & shipmentID
 * mixed in, this function takes the url query param id and returns an
 * object with shipmentId and if found apiKey
 * The function works with non encoded id query strings
 *
 * @param query - url query param id
 * @returns an object with api key if found & shipmentID
 */
export const decodeQueryString = (query: any) => {
  let shipmentId = '';
  let apiKey = '';

  if (query && query.length > 0) {
    let querySplit = query.split(':');

    if (querySplit.length === 1) {
      querySplit = query.split('-');
    }

    if (querySplit.length === 1) {
      shipmentId = query;
    } else {
      for (let i = 0; i < querySplit.length; i++) {
        if (i === 1 || i === 3 || i === querySplit.length - 1) {
          shipmentId += querySplit[i];
        } else {
          apiKey += querySplit[i];
        }
      }
    }
  }

  return {
    apiKey: apiKey || null,
    shipmentId: shipmentId || null
  };
};
