<template>
  <div class="app-promo-container">
    <div data-testid="tile" class="tile-btn-app-promo ripple" @click="handleTileClick" tabindex="0"
      @keydown.enter="handleTileClick">
      <div class="tile-btn-img">
        <img src="../assets/images/illustration-mobile-package.svg" alt="" width="120" height="120" />
      </div>
      <span class="tile-btn-text">
        <h2>{{ t('DOWNLOAD_PN_APP') }}</h2>
        <p>{{ t('APP_AVAILABLE_ANDROID_IPHONE') }}</p>
      </span>
      <div class="bulletlist-container">
        <ul>
          <li>{{ t('APP_PROMO_BULLETPOINT_1') }}</li>
          <li>{{ t('APP_PROMO_BULLETPOINT_2') }}</li>
          <li>{{ t('APP_PROMO_BULLETPOINT_3') }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import i18next, { t } from "i18next"

const emit = defineEmits(['tileClick'])

const handleTileClick = () => {
  window.dataLayer.push({ event: 'download_tile_clicked' })
  emit('tileClick', i18next.language)
}
</script>

<style lang="scss" scoped>
.app-promo-container {
  flex-direction: column;
  align-items: center;
  // max-width: 680px;
  width: 680px;

  @media only screen and (max-width: 680px) {
    width: 100%;
  }

  .tile-btn-app-promo {
    display: flex;
    flex-direction: column;
    border: 1px solid #d3cecb; // change to use color variable
    align-items: center;
    padding: 32px 24px 40px;
    box-sizing: border-box;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
      0px 1.6px 3.6px rgba(182, 26, 26, 0.13);
    border-radius: 0.8rem;
    cursor: pointer;
    transition: box-shadow 0.1s cubic-bezier(0.6, 0, 0.2, 1) 0.1s,
      background 0.2s linear, border 0.1s linear;
    user-select: text;
  }

  .ripple {
    background-position: center;
    transition: background 0.4s;
  }

  .ripple:active {
    background-color: $blue50;
    background-size: 100%;
    transition: background 0s;
  }

  .tile-btn-text {
    margin: 8px 0px;
    text-align: center;
  }

  .tile-btn-text h2 {
    color: $blue700;
    margin-bottom: 0.5em;
  }

  .bulletlist-container {
    align-items: flex-start;
    width: 100%;

    ul {
      list-style: none;
      padding-left: 0;
    }

    li {
      position: relative;
      padding-left: 1.5em;
      /* space to preserve indentation on wrap */
      margin-top: 1em;
    }

    li:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.4em;
      width: 1em;
      height: 1em;
      background: url('../assets/images/Icon-Stroke.svg') no-repeat;
    }
  }
}

.app-promo-container:hover {
  background-color: $blue50;
}
</style>
