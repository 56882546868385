const localStorageKey = 'tracking-previous-searches';

export const getPreviousSearches = () => {
  return JSON.parse(localStorage.getItem(localStorageKey) || '[]');
};

export const updatePreviousSearches = (search: string) => {
  const previousSearches = getPreviousSearches()
    .filter((previousSearch: string) => {
      return search !== previousSearch;
    })
    .slice(0, 2);

  localStorage.setItem(
    localStorageKey,
    JSON.stringify([search, ...previousSearches])
  );
};
