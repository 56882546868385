<template>
  <div class="landingView-container">
    <div class="search-component">
      <search-component @clickedSearch="findShipmentWidget" />
    </div>
    <div class="illustration-container">
      <img src="../assets/images/person-mobile.svg" alt="PostNord" />
      <div class="background-fill" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch } from "vue"
import SearchComponent from '../components/SearchComponent.vue';
import { RouteLocation, useRoute, useRouter } from "vue-router"

const { params } = useRoute()

const props = defineProps<{
  component?: string
}>()

console.log('landing language:', params.lang, props.component)

const route = useRoute()
const router = useRouter()

/**
 * Watch Route object to navigate with browser forward/backwards in landingView
 */
watch(route, (to: RouteLocation, from: RouteLocation) => {
  if (typeof to.query.id !== 'undefined') {
    router.push({
      name: 'LandingView',
      query: {
        id: to.query.id as string,
      },
      params: {
        lang: params.lang
      }
    });
  }
})

const findShipmentWidget = (shipmentId: string) => {
  if (!shipmentId) return;
  /**
   * the ternary expression is needed since widget takes swedish locale
   * as SV and the url param needs to be SE
   */
  router.push({
    name: 'Widget',
    params: {
      shipId: shipmentId
    }
  });
}
</script>

<style lang="scss" scoped>
.landingView-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow-x: hidden;

  .search-component {
    max-width: 700px;
    align-self: center;
    justify-self: end;
    padding-left: 2em;
  }

  .illustration-container {
    display: flex;
    position: relative;
    flex-shrink: 0;
    justify-self: center;
    .background-fill {
      position: absolute;
      align-self: center;
      top: 0;
      left: 99%;
      background: $blue700;
      height: 100%;
      width: 50vw;
    }
  }
}
@media only screen and (max-width: 880px) {
  .landingView-container {
    display: flex;
    flex-flow: column nowrap;
    overflow-x: visible;

    .search-component {
      margin-top: 0em;
      margin-left: 0em;
      margin-right: 0em;
      margin-bottom: 0em;
      padding-left: 0em;
    }

    .illustration-container {
      align-self: flex-end;
      margin-top: 2em;

      img {
        width: 33rem;
      }
      .background-fill {
        display: none;
      }
    }
  }
}
</style>
