<template>
  <div class="prev-search-container">
    <h2 v-if="previousSearches?.length">
      {{ t('TRACKING_PREVIOUS_SEARCHES') }}
    </h2>
    <ul>
      <li v-for="previousSearch in previousSearches" :key="previousSearch">
        <div v-if="previousSearch">
          <pn-button
            small
            variant="outlined"
            appearance="light"
            :icon="arrow_right"
            @click="clickedPrevSearch(previousSearch)"
          >
            {{ previousSearch }}
          </pn-button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { t } from 'i18next'
import { arrow_right } from 'pn-design-assets/pn-assets/icons'

defineProps<{
  previousSearches?: string[]
}>()

const emit = defineEmits(['clickedPrevSearch'])

const clickedPrevSearch = (searchId: string) => {
  window.dataLayer.push({ event: 'prev_search_clicked' });
  emit('clickedPrevSearch', searchId);
}
</script>

<style lang="scss" scoped>
.prev-search-container {
  h2 {
    margin-bottom: 0.5em;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;

    pn-button {
      margin-right: 0.5em;
      margin-top: 0.5em;
    }
  }
}
@media only screen and (max-width: 880px) {
  .prev-search-container {
    h2 {
      text-align: center;
    }

    ul {
      display: flex;

      li {
        width: 100%;

        pn-button {
          width: 100%;
          margin-bottom: 0.5em;
        }
      }
    }
  }
}
</style>
