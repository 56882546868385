import { defineCustomElement as PnButton } from '@postnord/web-components/components/pn-button';
import { defineCustomElement as PnSearchField } from '@postnord/web-components/components/pn-search-field';
import { defineCustomElement as PnTile } from '@postnord/web-components/components/pn-tile';
import { defineCustomElement as PnToast } from '@postnord/web-components/components/pn-toast';

import { createApp } from 'vue';

import PnWidget from 'pn-loader/TrackingWidgetLoader';

import { version } from '../../../package.json';

import App from './App.vue';
import router from './router';

import { translationInstance } from '@/localization/i18n'

PnButton();
PnSearchField();
PnToast();
PnTile();

const app = createApp(App)

// use translation plugin on app instance
translationInstance.usePlugin(app)

app.component('pn-widget', PnWidget)

app.use(router)

app.mount('#app')

// logging version in the console
console.log(`%c<Tracking SPA version="${version}" />`, [
  'color: #005D92',
  'font-weight: bold',
  'background: #ffffff',
  'font-family: "PostNordSans", "Verdana"',
  'font-size: 12px',
  'border: 2px solid #005D92',
  'box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.8)',
  'padding: 10px',
  'border-radius: 8px'
].join(';'));
