<template>
  <div class="app-nudge-container">
    <div class="pill-dot-container">
      <div class="dot" />
      <h2 class="header-mobile">{{ t('APP_NUDGE_HEADER') }}</h2>
      <div class="pill">
        <h2 class="pill-header-desktop">{{ t('APP_NUDGE_HEADER') }}</h2>
        <p class="pill-p">{{ t('APP_NUDGE_P') }}</p>
        <pn-button :icon="open_in_new" @click="goToDownload">Download</pn-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { t } from 'i18next'
import { open_in_new } from 'pn-design-assets/pn-assets/icons'

const goToDownload = () => {
  window.dataLayer.push({ event: 'download_tile_clicked' });
  window.location.href = 'https://wjf66.app.goo.gl/pnapp_dk';
}
</script>

<style lang="scss" scoped>
.app-nudge-container {
  width: 100%; // keep this as max
  max-width: 1228px;
  padding-top: 2em;

  .pill-dot-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1em;

    .dot {
      clip-path: circle(50px);
      height: 100px;
      width: 100px;
      aspect-ratio: 1/1;
      background-color: $blue50;
    }

    .header-mobile {
      display: none;
    }

    .pill {
      display: flex;
      align-items: center;
      background: $blue50;
      justify-content: space-between;
      padding: 1em;
      border-radius: 6rem;
      gap: 2em;

      @media only screen and (max-width: 1024px) {
        padding: 0.5em;
      }

      .pill-header-desktop {
        color: $blue900;
        font-weight: 500;
        padding-left: 1em;
      }
    }
  }
}

@media only screen and (max-width: 880px) {
  .app-nudge-container {
    .pill-dot-container {
      gap: 0em;

      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }

      .dot {
        border-radius: 0 10rem 10rem 0;
        clip-path: none;
        position: relative;
        width: 50px;
        left: -35px;

        @media only screen and (max-width: 698px) {
          display: none;

        }
      }

      .header-mobile {
        @media only screen and (max-width: 600px) {
          display: block;
          text-align: center;
          margin-bottom: 0.5em;
          color: $blue900;
          font-weight: 500;
        }
      }

      .pill {
        gap: 0;

        .pill-p {
          // background: red;
          padding-left: 2em;
          padding-right: 1em;
        }

        .pill-header-desktop {
          @media only screen and (max-width: 600px) {
            display: none;
          }
        }
      }
    }
  }
}
</style>
